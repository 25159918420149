import { FC, createRef, memo } from 'react';
import styles from '../../../ExpensionMap.module.scss';
import cx from 'classnames';
import { useExpansionMapHelper } from '../../../helper';
import { Districts } from '../../../../../constants/districts';

export const AltstadtSued: FC = memo(() => {
  const ref = createRef<SVGTextElement>();
  const {
    district,
    expansionStatusClass,
    isSelected,
    handleClick,
    handleHover,
  } = useExpansionMapHelper({
    district: Districts.AltstadtSued,
    ref,
  });

  return (
    <>
      <g
        className={cx(styles.districtWrapper, {
          [styles.isSelected]: isSelected,
        })}
        id={district}
        onClick={handleClick}
        onMouseEnter={handleHover}
      >
        <path
          className={cx(styles[expansionStatusClass], styles.districtArea)}
          aria-label="Altstadt-Süd"
          d="M483,645.5l17,0.5l4-6h8l2,6h24l2,6h20l9,46l-39,8c0,0-2.6-3.3-6.2-7.8C506.7,690.3,479.6,677,483,645.5z"
        />
        <text
          className={styles.districtName}
          ref={ref}
          transform="matrix(1 0 0 1 505.6621 667.0418)"
          textRendering="geometricPrecision"
        >
          <tspan x="0" y="0" className="st6 st3 st4">
            Altstadt-
          </tspan>
          <tspan x="0" y="14.4" className="st6 st3 st4">
            Süd
          </tspan>
        </text>
      </g>
    </>
  );
});
